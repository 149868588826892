import React, { useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';

import { Table, Button, Flex, DeprecatedLabel } from 'components';
import { sort, sortVersions } from 'lib/helpers/arrays';
import { useNotification, useAccess } from 'lib/hooks';
import parseDate from 'lib/helpers/parseDate';
import ITemplate from 'lib/Intefaces/ITemplate';
import GET_CONSENT_TEMPLATE, { SEARCH_CONSENT_TEMPLATES } from 'lib/gql/queries/getConsentTemplate';
import SearchInput, { SearchFilters } from './SearchInput';

import { AmountFound } from '../RepositoryTool/styled';
import EditTemplate from './Modals/EditTemplate';
import NewTemplate from './Modals/NewTemplate';
import { Container } from './styled';

const getColumns = (renderAction: (dps: ITemplate) => JSX.Element) => [
  {
    title: 'Service Name',
    key: 'dataProcessor',
    render: ({ isDeprecated, dataProcessor }: ITemplate) => (
      <span>
        {isDeprecated && <DeprecatedLabel small style={{ marginRight: '8px' }} />}
        {dataProcessor}
      </span>
    ),
    sorter: (a: ITemplate, b: ITemplate) => sort(a.dataProcessor, b.dataProcessor),
  },
  {
    title: 'Version',
    key: 'version',
    render: ({ version }: ITemplate) => <span>{version}</span>,
    sorter: (a: ITemplate, b: ITemplate) => sortVersions(a.version, b.version),
    width: '100px',
  },
  {
    title: 'Template ID',
    key: 'templateId',
    render: ({ templateId }: ITemplate) => <span>{templateId}</span>,
    sorter: (a: ITemplate, b: ITemplate) => sort(a.templateId, b.templateId),
  },
  {
    title: 'Last Update',
    key: 'lastUpdate',
    render: ({ updatedAt }: ITemplate) => <span>{parseDate(updatedAt)}</span>,
    sorter: (a: ITemplate, b: ITemplate) => sort(a.updatedAt, b.updatedAt),
    width: '160px',
  },
  {
    title: 'Action',
    key: 'action',
    render: (dps: ITemplate) => renderAction(dps),
  },
];

const getValue = (field: SearchFilters, searchTerm: string | string[]) => {
  if (Array.isArray(searchTerm) && field === SearchFilters.THIRD_COUNTRY_TRANSFER) {
    return (searchTerm as string[])?.join(',') || '';
  }
  if (Array.isArray(searchTerm)) {
    return searchTerm;
  }
  return [searchTerm];
};

const DataProcessingServices = () => {
  const [selectedTemplate, setSelectedTemplate] = useState<ITemplate | null>(null);
  const [tableData, setTableData] = useState<ITemplate[]>([]);
  const [showCreateDPS, setShowCreateDPS] = useState(false);
  const notification = useNotification();
  const { isReadAccess } = useAccess();

  const [searchWithFilter, { loading: loading1, error: error1 }] = useLazyQuery(SEARCH_CONSENT_TEMPLATES, {
    onCompleted: (response) => {
      const data: ITemplate[] = response.getConsentTemplateByTerm;
      setTableData(data);
    },
  });
  const [search, { loading: loading2, error: error2 }] = useLazyQuery(GET_CONSENT_TEMPLATE, {
    onCompleted: (response) => {
      const data: ITemplate[] = response.getConsentTemplateByDataProcessorOrTemplateId;
      setTableData(data.filter((el) => el.language === 'en'));
    },
  });

  const onSearch = (field: SearchFilters, searchTerm: string | string[]) => {
    if (field === SearchFilters.NAME_OR_ID) {
      search({ variables: { searchTerm } });
    } else {
      searchWithFilter({
        variables: {
          searchField: field,
          searchArray: getValue(field, searchTerm),
        },
      });
    }
  };

  useEffect(() => {
    if (error1 || error2) {
      notification.notify({ notificationMessage: (error1 || error2)?.message || '', type: 'error' });
    }
  }, [notification, error1, error2]);

  return (
    <Container>
      <Flex justifyContent="space-between" alignItems="center" style={{ marginBottom: '24px' }}>
        <SearchInput onSearch={onSearch} searching={loading1 || loading2} />
        <Button onClick={() => setShowCreateDPS(true)} disabled={isReadAccess} styleType="primary">
          New Template
        </Button>
      </Flex>
      <Table
        autoHeight
        loading={loading1 || loading2}
        columns={getColumns((dps) => (
          <Button styleType="inline" onClick={() => setSelectedTemplate(dps)} style={{ marginRight: '20px' }}>
            {isReadAccess ? 'View' : 'Edit'}
          </Button>
        ))}
        data={tableData?.map((dps: ITemplate) => ({ key: dps.templateId, ...dps }))}
      />
      {Boolean(tableData?.length) && <AmountFound>{tableData?.length} Data Processing Services found</AmountFound>}
      {showCreateDPS && (
        <NewTemplate
          open={showCreateDPS}
          onClose={() => {
            setShowCreateDPS(false);
          }}
        />
      )}
      {selectedTemplate && (
        <EditTemplate
          open={Boolean(selectedTemplate)}
          onClose={() => {
            setSelectedTemplate(null);
          }}
          template={selectedTemplate}
        />
      )}
    </Container>
  );
};

export default DataProcessingServices;
